import React from "react";

const CheckIcon: React.FC<React.HTMLAttributes<SVGSVGElement>> = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29 14L16.625 26L11 20.5455"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34 2H6C3.79086 2 2 3.79086 2 6V34C2 36.2091 3.79086 38 6 38H34C36.2091 38 38 36.2091 38 34V6C38 3.79086 36.2091 2 34 2Z"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckIcon;
