import React from 'react';

import { ReactComponent as add } from './icons/add.svg';
import { ReactComponent as checkmark } from './icons/checkmark.svg';
import { ReactComponent as chevronBack } from './icons/chevronBack.svg';
import { ReactComponent as chevronDown } from './icons/chevronDown.svg';
import { ReactComponent as chevronLeft } from './icons/chevronLeft.svg';
import { ReactComponent as chevronRight } from './icons/chevronRight.svg';
import { ReactComponent as chevronUp } from './icons/chevronUp.svg';
import { ReactComponent as editScrapbook } from './icons/editScrapbook.svg';
import { ReactComponent as locationPin } from './icons/locationPin.svg';
import { ReactComponent as markdown } from './icons/markdown.svg';
import { ReactComponent as more } from './icons/more.svg';
import { ReactComponent as playFilled } from './icons/playFilled.svg';
import { ReactComponent as search } from './icons/search.svg';
import { ReactComponent as videoCamera } from './icons/videoCamera.svg';
import { ReactComponent as x } from './icons/x.svg';
import { ReactComponent as xBig } from './icons/xBig.svg';

export const IconComponents = {
  add,
  checkmark,
  chevronBack,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronUp,
  editScrapbook,
  locationPin,
  markdown,
  more,
  playFilled,
  search,
  videoCamera,
  x,
  xBig,
};

export type IconName = keyof typeof IconComponents;

interface Props extends Omit<React.HTMLProps<SVGElement>, 'crossOrigin' | 'ref'> {
  name: IconName;
  color: string;
  size?: number;
}

const Icon = ({ name, color, size = 24, ...restProps }: Props): JSX.Element => {
  const IconComponent = IconComponents[name];

  return (
    <IconComponent
      // @ts-ignore
      style={{ width: size, height: size }}
      color={color}
      {...restProps}
    />
  );
};

export default Icon;
