import { useQuery } from "@apollo/client"
import { Helmet } from "react-helmet"
import { useParams } from "react-router"
import { ScrapbookQueryScraps, ScrapbookQueryScrapsVariables } from "../../apollo/types"
import { QueryScraps } from "./Scrapbook.gql"
import InfiniteScroll from 'react-infinite-scroll-component';
import Scrap from "../../component/partial/Scrap"
import uiState from "../../state/uiState"
import { action } from "mobx"
import { useEffect } from "react"
import { observer } from "mobx-react-lite"
import PasscodeForm from "../../component/partial/PasscodeForm"

const Scrapbook: React.FC = () => {
  const { scrapbookId } = useParams<{ scrapbookId: string }>()

  const { data, loading, error, fetchMore, refetch } = useQuery<ScrapbookQueryScraps, ScrapbookQueryScrapsVariables>(QueryScraps, {
    notifyOnNetworkStatusChange: true,
    variables: {
      scrapbookId,
      sortColumn: 'happenedAt',
      last: 10,
    },
  })

  useEffect(() => {
    if (data) {
      action(() => {
        uiState.currentScrapbookId = data.scrapbook.id
        uiState.currentScrapbookTitle = data.scrapbook.title
      })()
    }
  }, [data])

  return <>
    <Helmet>
      <title>{uiState.currentScrapbookTitle}</title>
      {/* <meta
        name="description"
        content="TODO:"
      /> */}
      <link rel="icon" href="/favicon.ico" />
    </Helmet>

    <div className="bg-key6 min-h-screen py-4">
      <div className="mx-auto max-w-2xl">
        {loading && <p>Loading…</p>}
        {error && error.message !== 'PASSCODE' && <p>Error! {error.message}</p>}
        {error && error.message === 'PASSCODE' && <PasscodeForm
          scrapbookId={scrapbookId}
          afterSubmit={refetch}
        />}

        {data && <InfiniteScroll
          next={async () => {
            await fetchMore({
              variables: {
                before: data.scraps.pageInfo.endCursor
              }
            })
          }}
          hasMore={data.scraps.pageInfo.hasPreviousPage}
          loader={<p>Loading more…</p>}
          dataLength={data.scraps.edges.length}
          className="space-y-4"
          // Override styles from InfiniteScroll
          style={{ overflow: 'visible' }}
        >
          {data.scraps.edges.map((scrap) => {
            return (<Scrap
              key={scrap.node.id}
              scrap={scrap.node}
              scrapbookUserRole={data.scrapbook.viewerRole}
              scrapbookPrivacyMode={data.scrapbook.privacyMode}
            />)
          })}
        </InfiniteScroll>}
      </div>
    </div>
  </>
}

export default observer(Scrapbook)
