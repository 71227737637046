import clsx from 'clsx';
import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMeasure } from 'react-use';

import { ScrapbookQueryScraps_scraps_edges_node_media } from "../../../../apollo/types";
import { buildPrimaryVariantUrl } from "../../../../util/imageTransmogrifier";
import { clamp } from '../../../../util/maths';

interface Props {
  media: ScrapbookQueryScraps_scraps_edges_node_media;
  sizeMode: 'autoWidth' | 'autoHeight';
  onClick: (e: React.MouseEvent<Element, MouseEvent>) => void;
  className?: string;
  naturalWidth: number;
  naturalHeight: number;
}

const FilmstripItem = React.forwardRef<HTMLImageElement, Props>(({
  media,
  sizeMode,
  onClick,
  className,
  naturalWidth,
  naturalHeight,
  ...restProps
}, ref): JSX.Element => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [measureRef, { width: wrapperWidth }] = useMeasure<HTMLDivElement>()

  let width = 0;
  let height = 0;

  if (sizeMode === 'autoHeight') {
    width = wrapperWidth;

    const minHeight = width * 0.6;
    const maxHeight = width * 1.25; // 5:4

    const naturalScaledHeight = naturalHeight * (width / naturalWidth);

    height = Math.min(maxHeight, Math.max(naturalScaledHeight, minHeight));
  } else {
    height = clamp(window.innerHeight * 0.3, 200, 320);

    const minWidth = height * 0.65;
    const maxWidth = height * 1.5;

    const naturalScaledWidth = naturalWidth * (height / naturalHeight);

    width = Math.min(maxWidth, Math.max(naturalScaledWidth, minWidth));
  }

  const filmstripItemImageUrl = buildPrimaryVariantUrl(
    media.primaryVariantKey as string,
    {
      w: width,
      h: height,
    },
  );

  return <div ref={measureRef} className={className}>
    <div
      ref={ref}
      style={{ width }}
      className="rounded-md bg-key6"
    >
      {width > 0 && height > 0 &&
        <LazyLoadImage
          onLoad={() => setImageLoaded(true)}
          src={filmstripItemImageUrl}
          width={width}
          height={height}
          className={clsx('rounded-md align-bottom transition-all', imageLoaded ? 'opacity-100' : 'opacity-0' )}
          onClick={onClick}
        />
      }
    </div>
  </div>
})

export default FilmstripItem
