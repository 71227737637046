import { observer } from "mobx-react-lite"
import { useLayoutEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import uiState from "../../../state/uiState"

const AppHeader = () => {
  const [spacerHeight, setSpacerHeight] = useState(0)
  const fixedHeaderRef = useRef<HTMLElement>(null)

  useLayoutEffect(() => {
    setSpacerHeight(fixedHeaderRef.current?.clientHeight || 0)
  }, [])

  const id = uiState.currentScrapbookId
  const title = uiState.currentScrapbookTitle

  return <>
    <header ref={fixedHeaderRef} className="fixed top-0 right-0 left-0 bg-key7 z-20 shadow-light">
      <div className="max-w-2xl mx-auto px-3 md:px-0 py-3">
        <h1 className="font-semibold text-lg">
          {!id && <>&nbsp;</>}

          {id && <Link to={`/sb/${id}`}>{title}</Link>}
        </h1>
      </div>
    </header>

    <div aria-hidden="true" style={{ height: spacerHeight }} />
  </>
}

export default observer(AppHeader)
