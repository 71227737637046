import { makeAutoObservable } from 'mobx';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import { AUTH_TOKEN_KEY } from '../config/localStorageKeys';

class AuthState {
  authInitialized = false;
  token?: string;
  decodedToken?: JwtPayload;

  constructor() {
    makeAutoObservable(this);
    this.initialize();
  }

  private initialize() {
    const storedToken = localStorage[AUTH_TOKEN_KEY];

    if (storedToken) {
      this.token = storedToken;
      this.decodedToken = jwtDecode(storedToken);
    }

    this.authInitialized = true;
  }

  setToken(newToken: string) {
    this.token = newToken;
    localStorage[AUTH_TOKEN_KEY] = newToken
    this.decodedToken = jwtDecode(newToken);
  }

  clear() {
    localStorage[AUTH_TOKEN_KEY] = null
    this.token = undefined;

    // Kick the whole thing over, just to be safe.
    // TODO: Send to a new location?
    window.location.reload()
  }
}

export default new AuthState();
