import React from "react";
import Helmet from "react-helmet";
import WaitlistForm from "../component/WaitlistForm/WaitlistForm";

const Homepage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Scrap Media | An easy way to post and share things online</title>
        <meta
          name="description"
          content="No ads, no trackers, no venture capital, no algorithmic feeds. Just an easy way to post and share things online. Share with friends and family, the world, or no one else at all."
        />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>

      <div className="flex flex-col justify-between min-h-screen">
        <div className="px-8 py-16 max-w-5xl mx-auto flex flex-col items-center">
          <h1 className="font-semibold text-4xl sm:text-7xl">Scrap Media</h1>
          <h2 className="text-xl sm:text-xl font-normal mt-2 text-key1">
            An easy way to post and share
          </h2>

          <p className="max-w-3xl text-l text-center text-key3 leading-8 sm:leading-10 mt-12">
            No ads, no trackers, no venture capital, no algorithmic feeds. Just an easy way to post and share things online. Share with friends and family, the world, or no one else at all.
          </p>

          <WaitlistForm />
        </div>

        <footer className="pt-0 pb-8 text-sm text-center">
          <p className="mt-6">&copy; {new Date().getFullYear()} Scrap Media</p>
          <p className="mt-1 text-xs text-key4">Built in Portland, OR</p>
        </footer>
      </div>
    </>
  );
};

export default Homepage;
