import { makeAutoObservable, reaction } from "mobx";

const scrapbookPasscodesKey = "scrap:scrapbookPasscodes";

class ScrapbookPasscodesState {
  scrapbookPasscodes: Record<string, string> = {};

  constructor() {
    this.scrapbookPasscodes = JSON.parse(
      window.localStorage.getItem(scrapbookPasscodesKey) || "{}",
    );

    makeAutoObservable(this);

    reaction(
      () => this.scrapbookPasscodes,
      (newPasscodes) => {
        window.localStorage.setItem(
          scrapbookPasscodesKey,
          JSON.stringify(newPasscodes),
        );
      },
    );
  }
}

export default new ScrapbookPasscodesState();
