import hljs from 'highlight.js';
import MarkdownIt from 'markdown-it'

interface Props {
  children: string;
}

const mdi = MarkdownIt({
  typographer: true,
  linkify: true,
  highlight: (str, lang) => {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(str, { language: lang }).value;
      } catch (__) { }
    }

    return ''; // use external default escaping
  }
}).disable(['link', 'image', 'heading'])

const defaultLinkRenderer = mdi.renderer.rules.link_open || ((tokens, idx, options, env, self) => {
  return self.renderToken(tokens, idx, options);
});

mdi.renderer.rules.link_open = (tokens, idx, options, env, self) => {
  tokens[idx].attrPush(['target', '_blank']);
  return defaultLinkRenderer(tokens, idx, options, env, self);
};

// const defaultVerticalMargin = {
//   marginTop: 6,
//   marginBottom: 6,
// }

const SomeMarkdown = ({ children }: Props) => {
  const markdownOutput = mdi.render(children)

  return <div className="text-sm some-markdown" dangerouslySetInnerHTML={{ __html: markdownOutput }} />
}

export default SomeMarkdown
