import clsx from "clsx"

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
}

const Input: React.FC<Props> = ({className, ...restProps}) => {
  return <input
    className={clsx('py-3', 'px-5', 'rounded-md', 'shadow-light', className)}
    {...restProps}
  />;
}

export default Input
