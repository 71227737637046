import React from 'react';
import { ApolloProvider } from '@apollo/client';

import client from '../apollo/client';

const AP: React.FC = ({ children }) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AP;
