import clsx from "clsx"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
}

const Button: React.FC<Props> = ({children, className, ...restProps}) => {
  return <button
    className={clsx('bg-key7', 'font-semibold', 'text-sm', 'py-3', 'px-5', 'shadow-light', 'rounded-md', className)}
    {...restProps}
  >{children}</button>
}

export default Button
