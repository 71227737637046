import { gql } from "@apollo/client";

export const QueryScraps = gql`
  query ScrapbookQueryScraps(
    $query: String
    $minLat: Float
    $minLon: Float
    $maxLat: Float
    $maxLon: Float
    $sortColumn: String!
    $scrapbookId: String!
    $before: String
    $after: String
    $first: Float
    $last: Float
  ) {
    scrapbook (id: $scrapbookId) {
      id
      title
      privacyMode
      viewerRole
    }

    scraps(
      query: $query
      minLat: $minLat
      minLon: $minLon
      maxLat: $maxLat
      maxLon: $maxLon
      sortColumn: $sortColumn
      scrapbookId: $scrapbookId
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      edges {
        cursor
        node {
          id
          scrapbookId
          media {
            id
            sortIndex
            mediaType
            mimeType
            originalExtension
            originalFilename
            primaryVariantExtension
            primaryVariantKey
            originalWidth
            originalHeight
            originalOrientation
          }
          author {
            id
          }
          createdAt
          happenedAt
          title
          notes
          placeDetails
          geog {
            coordinates
            crs
            type
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
