import React from 'react'

import { ScrapbookPrivacyMode, ScrapbookQueryScraps_scraps_edges_node, ScrapbookUserRole } from '../../../apollo/types';
import { format } from '../../../util/date';
import SomeMarkdown from '../../base/SomeMarkdown';
import Filmstrip from './Filmstrip';
import Menu from './Menu';

interface Props {
  scrap: ScrapbookQueryScraps_scraps_edges_node;
  scrapbookUserRole: ScrapbookUserRole | null;
  scrapbookPrivacyMode: ScrapbookPrivacyMode;
}

const Scrap: React.FC<Props> = ({ scrap, scrapbookPrivacyMode, scrapbookUserRole }) => {
  return <div className="bg-key7 shadow-light md:rounded-md">
    {!!scrap.media && scrap.media.length > 0 && (
      <Filmstrip media={scrap.media} />
    )}

    <div className="p-3">
      <div className="flex flex-row justify-between items-center">
        <div>
          {!!scrap.title && (
            <p
              className="text-lg text-key0 font-semibold mb-1"
            >
              {scrap.title}
            </p>
          )}
          <p className="text-xs text-key3">
            {format(scrap.happenedAt)}
            {scrap.placeDetails && (
              <button
                onClick={() => console.warn('TODO: Location filter')}
                className="ml-1"
              >
                <span className="">
                  • {scrap.placeDetails.name}
                </span>
              </button>
            )}
          </p>
        </div>

        <Menu scrapbookPrivacyMode={scrapbookPrivacyMode} scrapbookUserRole={scrapbookUserRole} scrap={scrap} />
      </div>

      {!!scrap.notes && <SomeMarkdown>{scrap.notes}</SomeMarkdown>}
    </div>
  </div>
}

export default Scrap;
