import { format as dfFormat } from 'date-fns';

export const format = (
  date: Date | string,
  formatString = 'MMMM d, yyyy',
): string => {
  if (typeof date !== 'object') {
    date = new Date(date);
  }
  return dfFormat(date, formatString);
};
