import { gql } from "@apollo/client";

export const QueryScrap = gql`
  query ScrapQueryScrap($scrapbookId: String!, $scrapId: String!) {
    scrapbook(id: $scrapbookId) {
      id
      title
      privacyMode
      viewerRole
    }

    scrap(scrapId: $scrapId, scrapbookId: $scrapbookId) {
      id
      scrapbookId
      media {
        id
        sortIndex
        mediaType
        mimeType
        originalExtension
        originalFilename
        primaryVariantExtension
        primaryVariantKey
        originalWidth
        originalHeight
        originalOrientation
      }
      author {
        id
      }
      createdAt
      happenedAt
      title
      notes
      placeDetails
      geog {
        coordinates
        crs
        type
      }
    }
  }
`;
