module.exports = {
  purge: ["./src/**/*.{js,jsx,ts,tsx}", "./public/index.html"],
  darkMode: "media",
  theme: {
    screens: {
      xs: "360px",
      sm: "540px",
      md: "672px",
      lg: "1024px",
      xl: "1280px",
      "2xl": "1536px",
    },
    fontFamily: {
      sans: ["Poppins", "sans-serif"],
    },
    extend: {},
    colors: {
      transparent: "transparent",
      current: "currentColor",
      key0: "#111111",
      key1: "#333333",
      key2: "#424242",
      key3: "#767676",
      key4: "#aaaaaa",
      key5: "#dddddd",
      key6: "#f4f4f4",
      key7: "#ffffff",
      success: "#018938",
      error: "#bf4444",
    },
    fontSize: {
      xs: ["12px", "16px"],
      sm: ["14px", "20px"],
      base: ["16px", "24px"],
      lg: ["18px", "24px"],
      xl: ["24px", "36px"],
      "2xl": ["36px", "54px"],
      "3xl": ["48px", "72px"],
      "4xl": ["60px", "88px"],
      "5xl": ["72px", "108px"],
    },
    boxShadow: {
      light: "0 1px 2px rgba(0, 0, 0, 0.1)",
      medium: "0 1px 3px rgba(0, 0, 0, 0.2)",
      elevated: "0 4px 16px rgba(0, 0, 0, 0.32)",
      none: "none",
    },
  },
  variants: {
    extend: {},
  },
  plugins: [],
};
