import React from "react";
import { useForm } from "react-hook-form";
import ArrowRightIcon from "../icons/ArrowRightIcon";
import CheckIcon from "../icons/CheckIcon";
import clsx from "clsx";

import {theme} from '../../tailwind.config'

interface SignupFormProps {
  className?: string;
}

type FormData = {
  form: string;
  email: string;
};

const SignupForm: React.FC<SignupFormProps> = ({ className }) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, isSubmitSuccessful, errors },
  } = useForm<FormData>();

  const disableInputs = isSubmitting || isSubmitSuccessful;

  const onSubmit = handleSubmit(async (data) => {
    const response = await fetch(process.env.REACT_APP_JOIN_WAITLIST_ENDPOINT, {
      method: "POST",
      body: JSON.stringify({ email: data.email }),
    });

    if (!response.ok) {
      setError("form", {
        type: "manual",
        message: "An error occurred, please try again.",
      });
    }
  });

  return (
    <div className={clsx("mt-8 w-full sm:max-w-lg", className)}>
      <p className="text-sm text-center text-key2 pb-2">
        Coming soon, join the waitlist!
      </p>

      <form onSubmit={onSubmit} method="POST">
        <div
          className={clsx(
            "p-3 pl-4 rounded-md flex transition-shadow shadow-md focus-within:shadow-lg transition-border border-l-4 border-key2 bg-key6",
            isSubmitSuccessful && "border-success",
            Object.keys(errors).length > 0 && "border-error",
          )}
        >
          <label htmlFor="email" className="sr-only">
            Email
          </label>

          <input
            disabled={disableInputs}
            type="email"
            id="email"
            className={clsx(
              "w-full appearance-none bg-white focus:outline-none hover:outline-none bg-transparent",
            )}
            placeholder="your@email.com"
            {...register("email", { required: true })}
          />

          <button
            disabled={disableInputs}
            type="submit"
            className={clsx(
              "text-red-400 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600",
              isSubmitting && "animate-pulse",
              isSubmitSuccessful && "text-green-400",
            )}
          >
            <span className="sr-only">Join the waitlist</span>

            {isSubmitSuccessful ? (
              <CheckIcon aria-hidden color={theme.colors.success} />
            ) : (
              <ArrowRightIcon aria-hidden color={theme.colors.key2} />
            )}
          </button>
        </div>

        {errors.form && (
          <span role="alert" className="block text-sm text-center text-gray-500 mt-3">
            {errors.form.message}
          </span>
        )}

        {errors.email && (
          <span role="alert" className="block text-sm text-center text-gray-500 mt-3">
            A valid email address is required.
          </span>
        )}

        {isSubmitSuccessful && (
          <span role="status" className="block text-sm text-center text-gray-500 mt-3">
            Thanks for signing up! We’ll be in touch soon.
          </span>
        )}
      </form>
    </div>
  );
};

export default SignupForm;
