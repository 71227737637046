import AppHeader from "../../base/AppHeader"

const AppLayout: React.FC = ({ children }) => {
  return <>
    <AppHeader />
    {children}
  </>
}

export default AppLayout
