import clsx from "clsx"
import React from "react"
import Icon from "../Icon"

interface Props extends Omit<React.HTMLProps<HTMLButtonElement>, 'type'> {
  title: string;
  description?: string;
  titleClassName?: string;
  descriptionClassName?: string;
  hideChevron?: boolean;
  hideBottomBorder?: boolean;
}

const ListItem = React.forwardRef<HTMLButtonElement, Props>(({
  title,
  description,
  titleClassName = 'color-key0',
  descriptionClassName = 'color-key1',
  hideBottomBorder,
  hideChevron,
  className,
  ...restProps
}, ref) => {
  return <button ref={ref} className={clsx('p-3 w-full flex flex-row justify-between items-center', hideBottomBorder ? null : 'border-b border-key5', className)} {...restProps}>
    <div className="flex flex-col items flex-shrink text-left text-sm">
      <h3 className={clsx('font-semibold', titleClassName)}>{title}</h3>

      {description && <p className={clsx('mt-1', descriptionClassName)}>{description}</p>}
    </div>

    {!hideChevron && (
      // FIXME: Theme color
      <Icon name="chevronRight" color="#333" className="ml-4 flex flex-shrink-0" />
    )}
  </button>
})

export default ListItem
