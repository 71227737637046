import { i18n, StringMap, TFunction, TOptions } from "i18next";
import {
  Namespace,
  useTranslation as i18nextUseTranslation,
  UseTranslationOptions,
  UseTranslationResponse,
} from "react-i18next";

/**
 * Wraps react-i18next's `useTranslation` hook and returns a `t` that returns
 * no content until `ready`.
 */
const useTranslation = (
  ns: Namespace,
  options?: UseTranslationOptions,
): UseTranslationResponse<typeof ns> => {
  const { t, i18n, ready }: UseTranslationResponse<typeof ns> = i18nextUseTranslation(ns, options);

  const delayedT: TFunction & { ns?: Namespace } = (
    key: string | string[],
    defaultValue?: string,
    tOptions?: TOptions<StringMap> | string,
  ) => {
    return ready ? t(key, defaultValue, tOptions) : "";
  };

  // When we use the `Trans` component, and pass in a `t` function, the Trans component is
  // grabbing the namespace off of the `t` function to determine which namespace it should use.
  // This is the source line for reference: https://github.com/i18next/react-i18next/blob/master/src/Trans.js#L296
  delayedT.ns = ns;

  // react-i18next returns a very strange type: A tuple/array which also has
  // properties for each array value set on it:
  // https://github.com/i18next/react-i18next/blob/master/src/useTranslation.js#L87-L90
  const ret: [TFunction, i18n, boolean] & { t?: TFunction; i18n?: i18n; ready?: boolean } = [
    delayedT,
    i18n,
    ready,
  ];

  ret.t = delayedT;
  ret.i18n = i18n;
  ret.ready = ready;

  return ret as UseTranslationResponse<typeof ns>;
};

export default useTranslation;
