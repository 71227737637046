import { makeAutoObservable } from "mobx";

class UiState {
  currentScrapbookTitle: string | null = null;
  currentScrapbookId: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }
}

export default new UiState();
