import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend'

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  // https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      // JSX is already escaped
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    }
  });

export default i18n;
