interface Params {
  w?: number;
  h?: number;
}

export const buildPrimaryVariantUrl = (
  primaryVariantKey: string,
  params: Params,
): string => {
  const url = new URL(`${process.env.REACT_APP_IMAGE_TRANSMOGRIFIER_ROOT}/${primaryVariantKey}`);
  Object.entries(params).forEach(([k, v]) => {
    if (k === 'w' || k === 'h') {
      v *= window.devicePixelRatio || 1;
    }

    url.searchParams.set(k, v);
  });

  return url.toString();
};
