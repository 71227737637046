import React from 'react'
import { Popover, Transition } from '@headlessui/react'
import useTranslation from '../../../../hook/useTranslation'
import Icon from '../../../base/Icon'
import ListItem from '../../../base/ListItem'
import { ScrapbookPrivacyMode, ScrapbookUserRole } from '../../../../apollo/types'

interface Props {
  scrapbookUserRole: ScrapbookUserRole | null;
  scrapbookPrivacyMode: ScrapbookPrivacyMode;
  scrap: {
    id: string;
    scrapbookId: string;
  };
}

const Menu = ({ scrapbookPrivacyMode, scrap }: Props) => {
  const { t } = useTranslation("partial-Scrap-Menu")

  const copyLinkDescriptions = {
    [ScrapbookPrivacyMode.PUBLIC]: t('copyLink.description.public'),
    [ScrapbookPrivacyMode.PASSCODE]: t('copyLink.description.passcode'),
    [ScrapbookPrivacyMode.PRIVATE]: t('copyLink.description.private'),
  };

  const copyLinkDescription = copyLinkDescriptions[scrapbookPrivacyMode];

  return (<Popover className="relative z-10">
    {({ open, close }) => {
      return (<>
        {/* FIXME: Theme color */}
        <Popover.Button><Icon name="more" color="#767676" /></Popover.Button>

        <Transition
          enter="transition duration-200 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-200 ease-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay
            className="opacity-30 fixed inset-0 bg-key0"
          />
        </Transition>

        <Transition
          enter="transition duration-100 ease-out origin-top-right"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-100 ease-out origin-top-right"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Popover.Panel className="absolute bg-key7 rounded-md right-0 w-72">
            <ListItem
              title={t('copyLink.title')}
              description={copyLinkDescription}
              hideBottomBorder={true}
              onClick={async () => {
                const link = new URL(`${process.env.REACT_APP_SCRAP_WEB_BASE_URL}/sb/${scrap.scrapbookId}/${scrap.id}`)

                await navigator.clipboard.writeText(link.toString())
                close()
              }}
            />
          </Popover.Panel>
        </Transition>
      </>)
    }}
  </Popover>)
}

export default Menu
