import { useQuery } from "@apollo/client"
import { Helmet } from "react-helmet"
import { useParams } from "react-router"
import { QueryScrap } from "./Scrap.gql"
import Scrap from "../../component/partial/Scrap"
import uiState from "../../state/uiState"
import { action } from "mobx"
import { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { ScrapQueryScrap, ScrapQueryScrapVariables } from "../../apollo/types"
import PasscodeForm from "../../component/partial/PasscodeForm"

const Scrapbook: React.FC = () => {
  const { scrapbookId, scrapId } = useParams<{ scrapbookId: string, scrapId: string }>()
  const [htmlTitle, setHtmlTitle] = useState('')

  const { data, loading, error, refetch } = useQuery<ScrapQueryScrap, ScrapQueryScrapVariables>(QueryScrap, {
    notifyOnNetworkStatusChange: true,
    variables: {
      scrapbookId,
      scrapId,
    },
  })

  useEffect(() => {
    if (data) {
      action(() => {
        const scrapbookTitle = data.scrapbook.title
        const scrapTitle = data.scrap.title

        uiState.currentScrapbookId = data.scrapbook.id
        uiState.currentScrapbookTitle = scrapbookTitle

        if (scrapTitle) {
          setHtmlTitle(`${scrapTitle} | ${scrapbookTitle}`)
        } else {
          setHtmlTitle(scrapbookTitle)
        }
      })()
    }
  }, [data])

  return <>
    <Helmet>
      <title>{htmlTitle}</title>
      {/* <meta
        name="description"
        content="TODO:"
      /> */}
      <link rel="icon" href="/favicon.ico" />
    </Helmet>

    <div className="bg-key6 min-h-screen py-4">
      <div className="mx-auto max-w-2xl">
        {loading && <p>Loading…</p>}
        {error && error.message !== 'PASSCODE' && <p>Error! {error.message}</p>}
        {error && error.message === 'PASSCODE' && <PasscodeForm
          scrapbookId={scrapbookId}
          afterSubmit={refetch}
        />}

        {data &&
          <div className="space-y-4">
            <Scrap
              key={data.scrap.id}
              scrap={data.scrap}
              scrapbookUserRole={data.scrapbook.viewerRole}
              scrapbookPrivacyMode={data.scrapbook.privacyMode}
            />
          </div>
        }
      </div>
    </div>
  </>
}

export default observer(Scrapbook)
