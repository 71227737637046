import clsx from "clsx";
import React, { RefObject } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import { useScrollBoost } from "react-scrollbooster";
import { ScrapbookQueryScraps_scraps_edges_node_media } from "../../../../apollo/types";
import { buildPrimaryVariantUrl } from "../../../../util/imageTransmogrifier";
import { getNaturalDimensions } from "../../../../util/media";
import FilmstripItem from "../FilmstripItem";

interface Props {
  media: ScrapbookQueryScraps_scraps_edges_node_media[];
}

const Filmstrip = ({ media }: Props): JSX.Element => {
  const [viewportRef] = useScrollBoost({
    direction: 'horizontal',
    scrollMode: 'transform',
    emulateScroll: true,
    // @ts-ignore
    // onWheel: (e) => {
    //   console.warn('wheel', e)
    // }
  })

  const sizeMode = media.length === 1 ? 'autoHeight' : 'autoWidth'

  return (
    <div>
      <div ref={viewportRef} className="overflow-hidden">
        <div
          className={clsx(
            'flex flex-row flex-nowrap',
            media.length === 1 ? '' : 'pl-1.5'
          )}
        >
          <Gallery>
            {media.map((m, i) => {
              const originalUrl = buildPrimaryVariantUrl(
                m.primaryVariantKey as string,
                {},
              )

              const { naturalWidth, naturalHeight } = getNaturalDimensions({
                media: m
              })

              return (
                <Item
                  thumbnail={originalUrl}
                  original={originalUrl}
                  width={naturalWidth}
                  height={naturalHeight}
                  key={m.id}
                  cropped
                >
                  {({ref, open}) => {
                    return <FilmstripItem
                      media={m}
                      ref={ref as RefObject<HTMLImageElement>}
                      naturalWidth={naturalWidth}
                      naturalHeight={naturalHeight}
                      sizeMode={sizeMode}
                      className={clsx(
                        media.length === 1 ? 'pt-3 px-3 flex-grow' : "pt-3 px-1.5",
                        i + 1 === media.length && media.length > 1 ? 'pr-3' : ''
                      )}
                      onClick={open}
                    />
                  }}
                </Item>
              )
            })}
          </Gallery>
        </div>
      </div>
    </div>
  )
}

export default Filmstrip;
