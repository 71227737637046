import { useMutation } from "@apollo/client"
import { Helmet } from "react-helmet"
import { useEffect, useState } from "react"
import useQueryParams from "../../hook/useQueryParams"
import { VerifyEmailVerifyUserEmail, VerifyEmailVerifyUserEmailVariables } from "../../apollo/types"
import { VerifyUserEmail } from "./VerifyEmail.gql"

const Scrapbook: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | Error>(null);
  const [successEmail, setSuccessEmail] = useState('');

  const queryParams = useQueryParams()
  const token = queryParams.get('emailVerificationToken')

  const [verifyUserEmail] = useMutation<VerifyEmailVerifyUserEmail, VerifyEmailVerifyUserEmailVariables>(
    VerifyUserEmail,
  );

  useEffect(() => {
    if (token) {
      const verify = async () => {
        setLoading(true)
        console.log('ummm going to verify token', token)

        try {
          const { data, errors } = await verifyUserEmail({variables: {
            verifyUserEmailInput: {
              emailVerificationToken: token
            }
          }})

          if (errors && errors.length > 0) {
            setError(errors[0])
          } else if (data?.verifyUserEmail) {
            setSuccessEmail(data.verifyUserEmail.emailAddress)
          }
        } catch (err) {
          if (err instanceof Error) {
            setError(err)
          }
        }

        setLoading(false)
      }

      verify();
    }
  }, [token, verifyUserEmail])


  return <>
    <Helmet>
      <title>Verify Email</title>
      <meta
        name="description"
        content="TODO:"
      />
      <link rel="icon" href="/favicon.ico" />
    </Helmet>

    <div className="bg-key6 min-h-screen py-4">
      <div className="mx-auto max-w-xl">
        {loading && <p>Loading…</p>}
        {error && <p>Error! {error.message}</p>}
        {successEmail && <p>Successfully verified {successEmail}</p>}
      </div>
    </div>
  </>
}

export default Scrapbook
