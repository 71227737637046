import { ScrapbookQueryScraps_scraps_edges_node_media } from "../apollo/types";

export const getNaturalDimensions = ({
  media,
}: {
  media: ScrapbookQueryScraps_scraps_edges_node_media;
}): {
  naturalWidth: number;
  naturalHeight: number;
} => {
  const orientation = media.originalOrientation;
  // https://sirv.com/help/articles/rotate-photos-to-be-upright/
  const isOnSide = !!orientation && [5, 6, 7, 8].includes(orientation);

  const naturalWidth = (
    isOnSide ? media.originalHeight : media.originalWidth
  ) as number;
  const naturalHeight = (
    isOnSide ? media.originalWidth : media.originalHeight
  ) as number;

  return {
    naturalWidth,
    naturalHeight,
  };
};
