/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScrapQueryScrap
// ====================================================

export interface ScrapQueryScrap_scrapbook {
  __typename: "Scrapbook";
  id: string;
  title: string;
  privacyMode: ScrapbookPrivacyMode;
  viewerRole: ScrapbookUserRole | null;
}

export interface ScrapQueryScrap_scrap_media {
  __typename: "Media";
  id: string;
  sortIndex: number;
  mediaType: MediaType;
  mimeType: string;
  originalExtension: string;
  originalFilename: string;
  primaryVariantExtension: string | null;
  primaryVariantKey: string | null;
  originalWidth: number | null;
  originalHeight: number | null;
  originalOrientation: number | null;
}

export interface ScrapQueryScrap_scrap_author {
  __typename: "User";
  id: string;
}

export interface ScrapQueryScrap_scrap_geog {
  __typename: "Point";
  /**
   * [lng, lat]
   */
  coordinates: number[];
  crs: any | null;
  type: string;
}

export interface ScrapQueryScrap_scrap {
  __typename: "Scrap";
  id: string;
  scrapbookId: string;
  media: ScrapQueryScrap_scrap_media[] | null;
  author: ScrapQueryScrap_scrap_author | null;
  createdAt: any;
  happenedAt: any;
  title: string | null;
  notes: string | null;
  placeDetails: any | null;
  geog: ScrapQueryScrap_scrap_geog | null;
}

export interface ScrapQueryScrap {
  scrapbook: ScrapQueryScrap_scrapbook;
  scrap: ScrapQueryScrap_scrap;
}

export interface ScrapQueryScrapVariables {
  scrapbookId: string;
  scrapId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ScrapbookQueryScraps
// ====================================================

export interface ScrapbookQueryScraps_scrapbook {
  __typename: "Scrapbook";
  id: string;
  title: string;
  privacyMode: ScrapbookPrivacyMode;
  viewerRole: ScrapbookUserRole | null;
}

export interface ScrapbookQueryScraps_scraps_edges_node_media {
  __typename: "Media";
  id: string;
  sortIndex: number;
  mediaType: MediaType;
  mimeType: string;
  originalExtension: string;
  originalFilename: string;
  primaryVariantExtension: string | null;
  primaryVariantKey: string | null;
  originalWidth: number | null;
  originalHeight: number | null;
  originalOrientation: number | null;
}

export interface ScrapbookQueryScraps_scraps_edges_node_author {
  __typename: "User";
  id: string;
}

export interface ScrapbookQueryScraps_scraps_edges_node_geog {
  __typename: "Point";
  /**
   * [lng, lat]
   */
  coordinates: number[];
  crs: any | null;
  type: string;
}

export interface ScrapbookQueryScraps_scraps_edges_node {
  __typename: "Scrap";
  id: string;
  scrapbookId: string;
  media: ScrapbookQueryScraps_scraps_edges_node_media[] | null;
  author: ScrapbookQueryScraps_scraps_edges_node_author | null;
  createdAt: any;
  happenedAt: any;
  title: string | null;
  notes: string | null;
  placeDetails: any | null;
  geog: ScrapbookQueryScraps_scraps_edges_node_geog | null;
}

export interface ScrapbookQueryScraps_scraps_edges {
  __typename: "ScrapEdge";
  /**
   * Used in `before` and `after` args
   */
  cursor: string;
  node: ScrapbookQueryScraps_scraps_edges_node;
}

export interface ScrapbookQueryScraps_scraps_pageInfo {
  __typename: "PageInfo";
  startCursor: string | null;
  endCursor: string | null;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface ScrapbookQueryScraps_scraps {
  __typename: "ScrapConnection";
  edges: ScrapbookQueryScraps_scraps_edges[];
  pageInfo: ScrapbookQueryScraps_scraps_pageInfo;
}

export interface ScrapbookQueryScraps {
  scrapbook: ScrapbookQueryScraps_scrapbook;
  scraps: ScrapbookQueryScraps_scraps;
}

export interface ScrapbookQueryScrapsVariables {
  query?: string | null;
  minLat?: number | null;
  minLon?: number | null;
  maxLat?: number | null;
  maxLon?: number | null;
  sortColumn: string;
  scrapbookId: string;
  before?: string | null;
  after?: string | null;
  first?: number | null;
  last?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: VerifyEmailVerifyUserEmail
// ====================================================

export interface VerifyEmailVerifyUserEmail_verifyUserEmail {
  __typename: "User";
  emailAddress: string;
}

export interface VerifyEmailVerifyUserEmail {
  verifyUserEmail: VerifyEmailVerifyUserEmail_verifyUserEmail;
}

export interface VerifyEmailVerifyUserEmailVariables {
  verifyUserEmailInput: VerifyUserEmailInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum MediaType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}

export enum ScrapbookPrivacyMode {
  PASSCODE = "PASSCODE",
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export enum ScrapbookUserRole {
  ADMIN = "ADMIN",
  OWNER = "OWNER",
  POSTER = "POSTER",
  VIEWER = "VIEWER",
}

export interface VerifyUserEmailInput {
  emailVerificationToken: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
