import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

import authState from "../state/authState";
import { fixedRelayStylePagination } from "./fixedRelayStylePagination";
import scrapbookPasscodesState from "../state/scrapbookPasscodesState";

const authLink = setContext(async (_, { headers = {} }) => {
  const accessToken = authState.token;
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  };
});

const tokenExpiredLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) console.warn("Network error:", networkError);

  // temporary for debugging
  // authState.clear();

  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      // console.warn("wat now?! (tokenExpiredLink)", err.message);
      // TODO: This needs to actually be hooked up on the server (cloudflare) side AND finalized on the GQL API side
      if (err.name === "TokenExpiredError") {
        authState.clear();
      }
    }
  }
});

const scrapbookPasscodeLink = new ApolloLink((operation, forward) => {
  const scrapbookId = operation.variables.scrapbookId;
  if (scrapbookId) {
    const savedPasscode =
      scrapbookPasscodesState.scrapbookPasscodes[scrapbookId];

    if (savedPasscode) {
      operation.setContext(({ headers = {} }) => {
        return {
          headers: {
            ...headers,
            "x-scrap-scrapbook-passcode": savedPasscode,
          },
        };
      });
    }
  }

  return forward(operation);
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // scraps: relayStylePagination((a, b) => {
          //   console.warn('rsp', a, b)
          //   return ['scrapbookId', 'before', 'after']
          // })
          scraps: fixedRelayStylePagination([
            "scrapbookId",
            "query",
            "minLat",
            "minLon",
            "maxLat",
            "maxLon",
            "sortColumn",
            // 'before',
            // 'after',
            "first",
            "last",
          ]),
        },
      },
    },
  }),
  link: authLink
    .concat(tokenExpiredLink)
    .concat(scrapbookPasscodeLink)
    .concat(httpLink),
});

export default client;
