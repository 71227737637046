import { gql } from "@apollo/client";

export const VerifyUserEmail = gql`
  mutation VerifyEmailVerifyUserEmail(
    $verifyUserEmailInput: VerifyUserEmailInput!
  ) {
    verifyUserEmail(verifyUserEmailInput: $verifyUserEmailInput) {
      emailAddress
    }
  }
`;
