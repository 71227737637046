import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "./i18n"
import "./styles/index.css";

import AppLayout from "./component/layout/AppLayout";
import Homepage from "./pages/Homepage";
import Scrapbook from "./pages/Scrapbook";
import Scrap from "./pages/Scrap";
import reportWebVitals from "./reportWebVitals";
import ApolloProvider from './provider/ApolloProvider';
import VerifyEmail from "./pages/VerifyEmail";

Sentry.init({
  dsn: "https://c9d74a0e1a5c4dfdb9b22e82d6ba533e@o1403253.ingest.sentry.io/6736076",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider>
      <Router>
        <Switch>
          <Route path="/" exact component={Homepage} />
          <Route path="/verify-email" exact component={VerifyEmail} />

          <Route>
            <AppLayout>
              <Switch>
                <Route path="/sb/:scrapbookId" exact component={Scrapbook} />
                <Route path="/sb/:scrapbookId/:scrapId" exact component={Scrap} />
              </Switch>
            </AppLayout>
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
