import { action } from 'mobx'
import React, { FormEvent, useState } from 'react'
import scrapbookPasscodesState from '../../../state/scrapbookPasscodesState'
import Button from '../../base/Button'
import Input from '../../base/Input'

interface Props {
  scrapbookId: string,
  afterSubmit: () => void
}

const PasscodeForm: React.FC<Props> = ({ scrapbookId, afterSubmit }) => {
  const [passcode, setPasscode] = useState('')

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    action(async () => {
      scrapbookPasscodesState.scrapbookPasscodes = {
        ...scrapbookPasscodesState.scrapbookPasscodes,
        [scrapbookId]: passcode
      }
      afterSubmit()
    })()
  }

  return <div className="flex flex-col items-center py-10">
    <p
      className="text-center mb-4 font-semibold px-2"
      style={{
        maxWidth: 320
      }}
    >This content is private, you need to know the passcode to view it</p>

    <form onSubmit={handleSubmit} className="max-w-full flex flex-initial animate__animated animate__headShake">
      <Input
        type="text"
        value={passcode}
        placeholder="Enter passcode…"
        onChange={(e) => setPasscode(e.target.value)}
      />

      <Button
        type="submit"
        className="ml-2"
      >Submit</Button>
    </form>
  </div>
}

export default PasscodeForm;
